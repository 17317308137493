import * as React from "react";
import Layout from "../_components/Layout/Layout";
import ErrorMessage from "../_components/ErrorMessage/ErrorMessage";

const Page404 = () => {
    return (
        <Layout hideFooterNavigation={true} title="404">
            <ErrorMessage
                content="We are really sorry but the page you're looking for does not exists."
            />
        </Layout>
    );
};
export default Page404;
